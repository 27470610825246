import Icon from '@mui/material/Icon'
import AdminManagement from 'layouts/admins'
import SignIn from 'layouts/authentication/sign-in'
import EventReports from 'layouts/event-reports'
import FeedCommentReports from 'layouts/feed-comment-report'
import FeedReports from 'layouts/feed-reports'
import UserReports from 'layouts/user-reports'
import UserManagement from 'layouts/users'

import FeedReplies from './layouts/feed-reply-report'

const routes = [
  // {
  //   type: 'collapse',
  //   name: 'Events',
  //   key: 'events',
  //   icon: <Icon fontSize='small'>table_view</Icon>,
  //   route: '/admin-view/events',
  //   component: <EventReports />,
  //   authVerification: true,
  //   private: true,
  // },
  {
    type: 'collapse',
    name: 'Talks',
    key: 'talks',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/admin-view/talks',
    component: <FeedReports />,
    authVerification: true,
    private: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Talk Comments',
  //   key: 'talkComments',
  //   icon: <Icon fontSize='small'>table_view</Icon>,
  //   route: '/admin-view/talk-commments',
  //   component: <FeedCommentReports />,
  //   authVerification: true,
  //   private: true,
  // },
  // {
  //   type: 'collapse',
  //   name: 'Talk Replies',
  //   key: 'talkComments',
  //   icon: <Icon fontSize='small'>table_view</Icon>,
  //   route: '/admin-view/talk-replies',
  //   component: <FeedReplies />,
  //   authVerification: true,
  //   private: true,
  // },
  // {
  //   type: 'collapse',
  //   name: 'User Reports',
  //   key: 'users',
  //   icon: <Icon fontSize='small'>table_view</Icon>,
  //   route: '/admin-view/users',
  //   component: <UserReports />,
  //   authVerification: true,
  //   private: true,
  // },
  {
    type: 'collapse',
    name: 'User Management',
    key: 'all-users',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/admin-view/all-users',
    component: <UserManagement />,
    authVerification: true,
    private: true,
  },
  {
    type: 'collapse',
    name: 'Admin Management',
    key: 'admins',
    icon: <Icon fontSize='small'>table_view</Icon>,
    route: '/admin-view/admins',
    component: <AdminManagement />,
    authVerification: true,
    private: true,
  },
  {
    type: 'collapse',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize='small'>login</Icon>,
    route: '/admin-view/sign-in',
    component: <SignIn />,
    authVerification: false,
    private: false,
  },
]

export type AppRoute = typeof routes

export default routes
